import { defaultsDeep } from 'lodash';
import { TemplateRegistry } from './templateRegistry';
import { PRICES_MAP } from './prices';

export const templatesRegistry = new TemplateRegistry();

const uiConfig = {
  paywallPage: {
    BoxPrice: 'default',
    AchieveMassive: 'default',
  },
};

templatesRegistry.register('HT9T45', {
  priceVariants: [PRICES_MAP['99_7day-3999_1month']],
  payWallText: 'paywallPage.subscription_info',
  uiConfig,
});

templatesRegistry.register('J7C4HK', {
  priceVariants: [PRICES_MAP['699_7day-3999_1month']],
  payWallText: 'paywallPage.subscription_info',
  uiConfig,
});

templatesRegistry.register('D2M99Z', {
  priceVariants: [PRICES_MAP['699_7day-3999_1month']],
  payWallText: 'paywallPage.subscription_info',
  uiConfig: defaultsDeep(
    {
      paywallPage: {
        BoxPrice: 'price_per_day',
        AchieveMassive: 'full_subscription_info',
      },
    },
    uiConfig
  ),
});

templatesRegistry.register(
  '63C1Z7',
  {
    enableTiktokAnalytics: true,
  },
  'D2M99Z'
);

templatesRegistry.register(
  'KR22P9',
  {
    priceVariants: [PRICES_MAP['699_7day-2999_1month']],
  },
  'D2M99Z'
);

templatesRegistry.register(
  'Z73CMZ',
  {
    priceVariants: [PRICES_MAP['699_7day-2999_1month']],
    enableTiktokAnalytics: true,
  },
  'D2M99Z'
);

templatesRegistry.register('F32OPT', {
  priceVariants: [
    PRICES_MAP['699_7day-2999_1month'],
    PRICES_MAP['1999_1month-2999_1month'],
    PRICES_MAP['2999_3month-5499_3month'],
  ],
  payWallText: 'paywallPage.subscription_info',
  uiConfig: defaultsDeep(
    {
      paywallPage: {
        BoxPrice: 'price_per_day',
        AchieveMassive: 'full_subscription_info',
      },
    },
    uiConfig
  ),
});

templatesRegistry.register(
  'T32OPT',
  {
    enableTiktokAnalytics: true,
  },
  'F32OPT'
);

templatesRegistry.register('99999', {
  priceVariants: [PRICES_MAP['0-2999_6month']],
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 7 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 month until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  uiConfig,
});

templatesRegistry.register('1', {
  templateId: '3',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 7 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 month until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_7day-2999_1month']],
  uiConfig,
});

templatesRegistry.register('2', {
  templateId: '4',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_14day-2999_3month']],
  uiConfig,
});

templatesRegistry.register('3', {
  templateId: '5',
  priceVariants: [PRICES_MAP['0_14day-2999_3month']],
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  uiConfig,
});

templatesRegistry.register('4', {
  templateId: '6',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 7 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 month until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_7day-2999_1month']],
  uiConfig,
});

templatesRegistry.register('5', {
  templateId: '7',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_14day-2999_3month']],
  uiConfig,
});

templatesRegistry.register('6', {
  templateId: '8',
  priceVariants: [PRICES_MAP['0_14day-2999_3month']],
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  uiConfig,
});

templatesRegistry.register('7', {
  templateId: '9',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $89.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_14day-8999_1year']],
  uiConfig,
});

templatesRegistry.register('8', {
  templateId: '10',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $89.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_14day-8999_1year']],
  uiConfig,
});

templatesRegistry.register('9', {
  templateId: '11',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 1 month free introductory offer, you will automatically be charged the full price of the subscription at $89.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_1month-8999_1year']],
  uiConfig,
});

templatesRegistry.register('11', {
  templateId: '13',
  payWallText:
    "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 1 month free introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
  priceVariants: [PRICES_MAP['0_1month-2999_1year']],
  uiConfig,
});
