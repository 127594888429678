import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthCookieContext } from './context';
import useCookie from './useCookie';
import { getLandingRegister } from './../api/api';
import { defaultsDeep, set } from 'lodash';
import trackAmplitude from '../services/analytics';
import { WEB_NEW_USER } from './AmplitudeTrackEvents';
import { DEFAULT_TEMPLATE_ID, FLOW_LIST } from './constants';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { initTikTokPixel } from '../services/initTikTokPixel';
import { templatesRegistry } from './templates';

const AuthCookieProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const templateIdStorage = localStorage.getItem('templateId');
  const templateIds = templatesRegistry.getTemplateIds();
  const templateId = templateIds.includes(templateIdStorage)
    ? templateIdStorage
    : DEFAULT_TEMPLATE_ID;

  const template = useMemo(
    () => templatesRegistry.getConfig(templateId),
    [templateId]
  );

  const [userInfo, setUserInfo] = useState({
    templateId,
    paypal: {
      selectedPaymentId: template?.priceVariants?.[0]?.paypal,
      paymentId: template?.priceVariants?.[0]?.paypal,
      bestOfferPaymentId: template?.priceVariants?.[0]?.paypal,
    },
    stripe: {
      selectedPaymentId: template?.priceVariants?.[0]?.stripe,
      paymentId: template?.priceVariants?.[0]?.stripe,
      bestOfferPaymentId: template?.priceVariants?.[0]?.stripe,
    },
    locale: 'en',
  });

  useCookie({ data: userInfo });

  const loadUserInfo = useCallback(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const debug = urlParams.get('debug') || '0';

    // const pathname = window.location.pathname;

    let flow = FLOW_LIST.join('/');
    // if (
    //   !LIST_PAGES.includes(pathname) &&
    //   pathname !== '/' &&
    //   pathname.startsWith('/al:')
    // ) {
    //   flow = `al:${window.location.pathname.replace('/al:', '')}`;
    // }

    getLandingRegister({ debug, flow })
      .then((json) => {
        const newLocale = json?.locale || 'en';
        const oldLocale = localStorage.getItem('smmhub_locale');

        if (oldLocale !== newLocale) {
          localStorage.setItem('smmhub_locale', newLocale);
          dayjs.locale(newLocale);
        }

        i18n.changeLanguage(newLocale);

        setUserInfo((prev) => defaultsDeep(json, prev));

        if (json.is_first_query) {
          trackAmplitude(WEB_NEW_USER, {
            screen: json.screen_id,
            user_id: json.user_id,
          });
        }
      })
      .then(() => {
        if (!userInfo?.countryCode) {
          fetch('https://ipapi.co/json/')
            .then((r) => r.json())
            .then(({ country_code }) => {
              setUserInfo((prev) => ({
                ...set(prev, 'countryCode', country_code),
              }));
            });
        }
      });
  }, [i18n, userInfo?.countryCode]);

  useEffect(() => {
    loadUserInfo();
  }, [loadUserInfo]);

  useEffect(() => {
    const templateId = userInfo?.templateId;
    if (templateId) {
      localStorage.setItem('templateId', templateId);
      const paypal = {
        selectedPaymentId: template?.priceVariants?.[0]?.paypal,
        paymentId: template?.priceVariants?.[0]?.paypal,
        bestOfferPaymentId: template?.priceVariants?.[0]?.paypal,
      };
      const stripe = {
        selectedPaymentId: template?.priceVariants?.[0]?.stripe,
        paymentId: template?.priceVariants?.[0]?.stripe,
        bestOfferPaymentId: template?.priceVariants?.[0]?.stripe,
      };
      setUserInfo((prev) => ({
        ...set(prev, 'stripe', stripe),
        ...set(prev, 'paypal', paypal),
      }));

      initTikTokPixel(templateId);
    }
  }, [template, userInfo?.templateId]);

  console.log(userInfo);
  return (
    <AuthCookieContext.Provider
      value={{
        userInfo,
        setUserInfo,
        loadUserInfo,
      }}
    >
      {children}
    </AuthCookieContext.Provider>
  );
};

export default AuthCookieProvider;
